.flash-message {
  max-width: 22.5rem;

  &-wrapper {
    position: fixed;
    right: 2rem;
    top: 2rem;
    z-index: 9999;
  }
}
