.tiers {
  &-image-wrapper {
    position: relative;
    width: 9.375rem;
    height: 9.375rem;
    margin: auto;
  }

  &-image {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%);
    padding: 2px;

    > img {
      background: $background-color;
      margin: auto;
      //padding: 0.125rem;
      border-radius: 50%;
      z-index: 1;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 50% 50%, 50% 50%;
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
      border-radius: 50%;
      z-index: -2;
    }

    &-0 {
      img {
        box-shadow: rgb(255 191 53 / 41%) 0 0 10px 2px;
      }
      &:before {
        background-image: linear-gradient(132deg, rgba(255, 191, 53, 1), rgba(255, 98, 0, 1) 43%, rgba(255, 191, 53, 1)),
        linear-gradient(132deg, rgba(255, 191, 53, 1), rgba(255, 98, 0, 1) 43%, rgba(255, 191, 53, 1)),
        linear-gradient(132deg, rgba(255, 191, 53, 1), rgba(255, 98, 0, 1) 43%, rgba(255, 191, 53, 1)),
        linear-gradient(132deg, rgba(255, 191, 53, 1), rgba(255, 98, 0, 1) 43%, rgba(255, 191, 53, 1));
      }
    }
    &-1 {
      img {
        box-shadow: rgb(155 208 255 / 59%) 0 0 10px 2px;
      }
      &:before {
        background-image: linear-gradient(132deg, rgba(155, 208, 255, 1), rgba(0, 113, 255, 1) 43%, rgba(155, 208, 255, 1)),
        linear-gradient(132deg, rgba(155, 208, 255, 1), rgba(0, 113, 255, 1) 43%, rgba(155, 208, 255, 1)),
        linear-gradient(132deg, rgba(155, 208, 255, 1), rgba(0, 113, 255, 1) 43%, rgba(155, 208, 255, 1)),
        linear-gradient(132deg, rgba(155, 208, 255, 1), rgba(0, 113, 255, 1) 43%, rgba(155, 208, 255, 1));
      }
    }
    &-2 {
      img {
        box-shadow: rgb(215 215 215 / 43%) 0 0 10px 2px;
      }
      &:before {
        background-image: linear-gradient(132deg, rgba(215, 215, 215, 1), rgba(141, 141, 141, 1) 43%, rgba(215, 215, 215, 1)),
        linear-gradient(132deg, rgba(215, 215, 215, 1), rgba(141, 141, 141, 1) 43%, rgba(215, 215, 215, 1)),
        linear-gradient(132deg, rgba(215, 215, 215, 1), rgba(141, 141, 141, 1) 43%, rgba(215, 215, 215, 1)),
        linear-gradient(132deg, rgba(215, 215, 215, 1), rgba(141, 141, 141, 1) 43%, rgba(215, 215, 215, 1));
      }
    }
    &-3 {
      img {
        box-shadow: rgb(255 135 92 / 48%) 0 0 10px 2px;
      }
      &:before {
        background-image: linear-gradient(132deg, rgba(255, 135, 92, 1), rgba(255, 1, 102, 1) 43%, rgba(255, 135, 92, 1)),
        linear-gradient(132deg, rgba(255, 135, 92, 1), rgba(255, 1, 102, 1) 43%, rgba(255, 135, 92, 1)),
        linear-gradient(132deg, rgba(255, 135, 92, 1), rgba(255, 1, 102, 1) 43%, rgba(255, 135, 92, 1)),
        linear-gradient(132deg, rgba(255, 135, 92, 1), rgba(255, 1, 102, 1) 43%, rgba(255, 135, 92, 1));
      }
    }
    &-4 {
      img {
        box-shadow: rgb(255 198 141 / 48%) 0 0 10px 2px;
      }
      &:before {
        background-image: linear-gradient(132deg, rgba(255, 198, 141, 1), rgba(255, 94, 74, 1) 43%, rgba(255, 198, 141, 1)),
        linear-gradient(132deg, rgba(255, 198, 141, 1), rgba(255, 94, 74, 1) 43%, rgba(255, 198, 141, 1)),
        linear-gradient(132deg, rgba(255, 198, 141, 1), rgba(255, 94, 74, 1) 43%, rgba(255, 198, 141, 1)),
        linear-gradient(132deg, rgba(255, 198, 141, 1), rgba(255, 94, 74, 1) 43%, rgba(255, 198, 141, 1));
      }
    }
    &-5 {
      img {
        box-shadow: rgb(138 253 138 / 45%) 0 0 10px 2px;
      }
      &:before {
        background-image: linear-gradient(132deg, rgba(138, 253, 138, 1), rgba(95, 177, 95, 1) 43%, rgba(138, 253, 138, 1)),
        linear-gradient(132deg, rgba(138, 253, 138, 1), rgba(95, 177, 95, 1) 43%, rgba(138, 253, 138, 1)),
        linear-gradient(132deg, rgba(138, 253, 138, 1), rgba(95, 177, 95, 1) 43%, rgba(138, 253, 138, 1)),
        linear-gradient(132deg, rgba(138, 253, 138, 1), rgba(95, 177, 95, 1) 43%, rgba(138, 253, 138, 1));
      }
    }
    &-6 {
      img {
        box-shadow: rgb(0 133 255 / 48%) 0 0 10px 2px;
      }
      &:before {
        background-image: linear-gradient(132deg, rgba(0, 133, 255, 1), rgba(0, 35, 109, 1) 43%, rgba(0, 133, 255, 1)),
        linear-gradient(132deg, rgba(0, 133, 255, 1), rgba(0, 35, 109, 1) 43%, rgba(0, 133, 255, 1)),
        linear-gradient(132deg, rgba(0, 133, 255, 1), rgba(0, 35, 109, 1) 43%, rgba(0, 133, 255, 1)),
        linear-gradient(132deg, rgba(0, 133, 255, 1), rgba(0, 35, 109, 1) 43%, rgba(0, 133, 255, 1));
      }
    }
    &-7 {
      img {
        box-shadow: rgb(44 186 186 / 44%) 0 0 10px 2px;
      }
      &:before {
        background-image: linear-gradient(132deg, rgba(44, 186, 186, 1), rgba(13, 120, 161, 1) 43%, rgba(44, 186, 186, 1)),
        linear-gradient(132deg, rgba(44, 186, 186, 1), rgba(13, 120, 161, 1) 43%, rgba(44, 186, 186, 1)),
        linear-gradient(132deg, rgba(44, 186, 186, 1), rgba(13, 120, 161, 1) 43%, rgba(44, 186, 186, 1)),
        linear-gradient(132deg, rgba(44, 186, 186, 1), rgba(13, 120, 161, 1) 43%, rgba(44, 186, 186, 1));
      }
    }
    &-8 {
      img {
        box-shadow: rgb(255 199 170 / 47%) 0 0 10px 2px;
      }
      &:before {
        background-image: linear-gradient(132deg, rgba(255, 199, 170, 1), rgba(255, 98, 197, 1) 43%, rgba(255, 199, 170, 1)),
        linear-gradient(132deg, rgba(255, 199, 170, 1), rgba(255, 98, 197, 1) 43%, rgba(255, 199, 170, 1)),
        linear-gradient(132deg, rgba(255, 199, 170, 1), rgba(255, 98, 197, 1) 43%, rgba(255, 199, 170, 1)),
        linear-gradient(132deg, rgba(255, 199, 170, 1), rgba(255, 98, 197, 1) 43%, rgba(255, 199, 170, 1));
      }
    }
    &-9 {
      img {
        box-shadow: rgb(215 170 248 / 46%) 0 0 10px 2px;
      }
      &:before {
        background-image: linear-gradient(132deg, rgba(215, 170, 248, 1), rgba(130, 105, 241, 1) 43%, rgba(215, 170, 248, 1)),
        linear-gradient(132deg, rgba(215, 170, 248, 1), rgba(130, 105, 241, 1) 43%, rgba(215, 170, 248, 1)),
        linear-gradient(132deg, rgba(215, 170, 248, 1), rgba(130, 105, 241, 1) 43%, rgba(215, 170, 248, 1)),
        linear-gradient(132deg, rgba(215, 170, 248, 1), rgba(130, 105, 241, 1) 43%, rgba(215, 170, 248, 1));
      }
    }
  }

  .card {
    &:hover {
      .tiers-image:before {
        animation: 4s linear 0s infinite normal none running rotate;
      }
    }
  }
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@-moz-keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@-ms-keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@-o-keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
