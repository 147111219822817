$progress-width: var(--progress-width);

.progress {
  height: 1rem;
  background-color: #092321;
  border-radius: 2rem;
}

.progress-bar {
  height: 100%;
  border-radius: 2rem;
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-animate .progress-bar {
  width: $progress-width;
  background-color: $color-primary;
  animation: progressAnimate 2s;
}

@keyframes progressAnimate {
  0%   { width: 0;}
  100% { width: $progress-width;}
}
