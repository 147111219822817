body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $text-weight-base;
  line-height: $line-height-base;
  color: $body-color;
  background-color: $background-color;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  > span {
    @extend .text-highlight;
  }
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

section {
  margin: 8rem 0;

  @media screen and (max-width: $screen-md-min) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

a {
  color: $color-link;
  text-decoration: none;
  @extend .transition-ease-primary;

  &:hover, &:active, &:focus {
    color: $color-link-hover !important;
  }
}

nav {
  //position: sticky;
  //background-color: $background-color;
  padding: 1.25rem 0;
  width: 100%;
  //top: 0;
  z-index: 1;
}

ul {
  font-size: $font-size-h5
}

hr {
  background-color: #505072;
}

// Image
img {
  width: auto;
  max-width: 100%;
}

label {
  color: #999999;
  font-size: 16px;
}

::selection {
  color: #ffffff;
  background-color: $color-marv;
}

.pointer-events-none {
  pointer-events: none;
  user-focus-pointer: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cursor-pointer {
  cursor: pointer;
}




