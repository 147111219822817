.block-wrapper {
  background-color: $color-dark;
  padding: 2rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  border-radius: .5rem;
  @extend .transition-ease-primary;

  &-hover:hover {
    background-color: darken($color-dark, 2);
  }
}
