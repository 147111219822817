// Transition
// Do not change transition time as this is being used many places
// Note: this is applied to the main class while .transition-ease-in-primary is applied to hover
.transition-ease-primary {
  @include transition-ease(all, .25s, ease-in-out);
}

// Do not move this up
.transition-reset {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
