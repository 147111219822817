.gradient-circle {
  position: fixed;
  top: 15rem;
  right: auto;
  bottom: auto;
  left: -15rem;
  width: 72vh;
  height: 72vh;
  border-radius: 50%;
  background-image: radial-gradient(rgb(67 111 157), rgba(255, 255, 255, 0.25));
  opacity: 0.2;
  -webkit-filter: blur(56px);
  filter: blur(56px);
  pointer-events: none;
  z-index: 2;

  &--primary {
    background-image: radial-gradient(rgb(239 101 93 / 75%), rgb(129 73 191 / 18%));
    top: -15rem;
    right: -15rem;
    bottom: auto;
    left: auto;
  }
}
