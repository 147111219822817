.project {

  &-card {
    overflow: visible;

    &__image {
      width: 5.25rem;
      height: 5.25rem;
      border: 2px solid;
      background: linear-gradient($background-color, $background-color) padding-box,
      linear-gradient(180deg, rgba(129, 132, 255, 0.2) 0%, rgba(129, 132, 255, 0) 100%) border-box;
      border-image-source: linear-gradient(180deg, rgba(129, 132, 255, 0.2) 0%, rgba(129, 132, 255, 0.03) 100%);
      padding: .5rem;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }

    &__desc {
      min-height: 6rem;
    }

    &__list-item {
      @include display-flex(center, space-between);
      background: linear-gradient(#0F0F1F, $background-color) padding-box,
      linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%) border-box;
      cursor: default;
      font-weight: 700;
      border: 1px solid transparent;
      padding: .75rem 1.25rem;
      text-transform: uppercase;
      -webkit-border-radius: 1rem;
      -moz-border-radius: 1rem;
      border-radius: 1rem;
    }

    &--hover {
      &:before {
        content: "";
        position: absolute;
        background: linear-gradient(134deg, $color-orange 45%, $color-primary 45%);
        top: -.25rem;
        bottom: -.25rem;
        left: -.25rem;
        right: -.25rem;
        opacity: 0;
        z-index: -1;
        -webkit-filter: blur(96px);
        filter: blur(96px);
        @extend .transition-ease-primary;
      }

      &:hover {
        cursor: pointer;

        &:before {
          opacity: 0.7;
        }
      }
    }
  }

  &-controls {
    @media all and (max-width: $screen-sm-max) {
      .btn {
        border: 0;
        padding: 0;

        > svg {
          display: none;
        }
      }
    }
  }
}
