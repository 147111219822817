.textarea {
  display: block;
  width: 100%;
  min-height: 2.75rem;
  font-size: $font-size-md;
  color: $color-white;
  background-color: $color-dark;
  border: 1px solid transparent;
  padding: 1rem;
  line-height: 1.5;
  outline: 0;
  -webkit-appearance: none;
  @include border-radius(0.5rem);
  @include box-sizing(border-box);
}
