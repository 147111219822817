.tabs-wrapper {
  position: sticky;
  top: 0;
  background-color: $background-color;
  padding-bottom: .75rem;
  z-index: 1;
}

.tabs {
  @include display-flex(center, center);
  background-color: $color-dark;
  margin: 0;
  padding: 0;
  overflow: auto;

  @media(max-width: $screen-sm-max) {
    justify-content: flex-start;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__item {
    @include display-flex(center);
    flex: 0 0 auto;
    width: auto;
    color: $color-gray;
    font-size: 1rem;
    font-weight: bold;
    position: relative;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    margin: 0 1.5rem;
    text-transform: uppercase;
    @extend .transition-ease-primary;

    // Only if there are multiple tabs then chow pointer cursor
    &:not(:only-child) {
      cursor: pointer;
    }

    &:before {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      background: linear-gradient(90deg, $color-marv 1.74%, $color-orange 100%);
      transform: scaleX(0);
      transition: transform .2s ease-in-out;
      @include border-radius(2px, 2px, 0, 0);
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &--active:before {
      border-bottom-color: $color-warning;
    }

    &--active,
    &:hover:not(&--active) {
      color: $color-white;
      opacity: 1;

      &:before {
        transform: scaleX(1);
      }
    }

    &--disabled {
      pointer-events: none;
    }

    &--disabled:hover {
      &:before {
        transform: scaleX(0) !important;
      }
    }

    > svg {
      margin-right: .75rem;
      @extend .transition-ease-primary;

      @media(max-width: $screen-sm-max) {
        display: none;
      }
    }
  }
}

.tab-content {
  margin-top: 1.5rem;

  &__item {
    display: none;

    &--active {
      display: block;
    }

    // change margin to padding to fix scroll issue
    > section:first-child {
      margin-top: 0;
      padding-top: 6rem;
    }
  }
}
