.loader-spinner {
  height: 1.25rem;
  width: 1.25rem;

  svg {
    width: 100%;
    height: 100%;
    margin: auto;
    -webkit-animation: loader-spinner-rotate 1.6s linear infinite;
    animation: loader-spinner-rotate 1.6s linear infinite;
    transform-origin: center center;

    circle {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: loader-spinner-dash 1.5s ease-in-out infinite, loader-spinner-colors 6s ease-in-out infinite;
      stroke-linecap: round;
    }
  }

  // Sizes
  &--sm {
    height: 1.125rem;
    width: 1.126rem;
  }
  &--md {
    height: 2rem;
    width: 2rem;
  }
  &--lg {
    height: 2.5rem;
    width: 2.5rem;
  }

  &--center,
  &--content {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
  }

  // Loader for page content
  &--content {
    @extend .loader-spinner--lg;
    z-index: 3; // Required to push loader in front of power-select

    // For large screens
    @media screen and (min-height: 700px) {
      top: calc(50% - 8rem);
    }
  }
}

// Keyframes for loader spinner
@keyframes loader-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@-webkit-keyframes loader-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader-spinner-colors {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}
