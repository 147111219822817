.rs-container * {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.rs-container {
  font-family: Arial, Helvetica, sans-serif;
  height: 45px;
  position: relative
}

.rs-container .rs-bg, .rs-container .rs-selected {
  background: linear-gradient(180deg, rgba(53, 202, 125, 0.15) 0%, rgba(53, 202, 125, 0.0521875) 96.87%, rgba(253, 233, 149, 0.05) 100%);
  height: 10px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 100%;
  border-radius: 8px
}

.rs-container .rs-selected {
  background: linear-gradient(90deg, $color-primary 1.74%, $color-orange 100%);
  border: none;
  transition: all .2s linear;
  width: 0
}

.rs-container.disabled .rs-selected {
  background-color: #ccc;
  border-color: #bbb
}

.rs-container .rs-pointer {
  background: linear-gradient(47.91deg, $color-primary 14.16%, $color-orange 84.58%);
  cursor: pointer;
  height: 20px;
  width: 20px;
  left: -10px;
  position: absolute;
  top: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  transition: all .2s linear;
}

.rs-container.disabled .rs-pointer {
  border-color: #ccc;
  cursor: default
}

.rs-container.sliding .rs-pointer, .rs-container.sliding .rs-selected {
  transition: none
}

.rs-container .rs-scale {
  left: 0;
  position: absolute;
  top: 5px;
  white-space: nowrap
}

.rs-container .rs-scale span {
  float: left;
  position: relative
}

.rs-container .rs-scale span::before {
  background-color: #2e2e2e;
  content: "";
  height: 8px;
  left: 0;
  position: absolute;
  top: 12px;
  width: 1px;
}

.rs-container.rs-noscale span::before {
  display: none
}

.rs-container.rs-noscale span:first-child::before, .rs-container.rs-noscale span:last-child::before {
  display: block
}

.rs-container .rs-scale span:last-child {
  margin-left: -1px;
  width: 0
}

.rs-container .rs-scale span ins {
  color: #fff;
  display: inline-block;
  font-size: 12px;
  margin-top: 20px;
  text-decoration: none;
  cursor: pointer;
}

.rs-container.disabled .rs-scale span ins {
  color: #999
}

.rs-container.disabled .rs-tooltip {
  border-color: #ccc;
  color: #999
}
