.hero {
  height: 100%;
  min-height: 100vh;
  background: url(/assets/images/hero-bg.svg);
  background-size: cover;
  padding-top: 10rem;

  @media screen and (max-width: $screen-md-min) {
    padding-top: 7rem;
  }

  @media screen and (max-width: $screen-sm-max) {
    background-size: auto;
  }

  &--mint {
    background: url(/assets/images/hero-mint-bg.svg);
    background-size: cover;
  }

  &-image {
    > svg path {
      //animation: glow 3s linear infinite;
    }
  }

  &-title {
    font-style: italic;
    font-weight: 700;
    font-size: 4.25rem;
    line-height: 1.4;
    text-align: center;
    letter-spacing: 0.095em;
    text-transform: uppercase;
    margin-top: 2rem;
    margin-bottom: .5rem;

    @media screen and (max-width: $screen-md-min) {
      font-size: 3rem;
    }
  }

  &-subtitle {
    font-weight: 300;
    font-size: 1.25rem;
    max-width: 48rem;
    line-height: 1.6;
    text-align: center;
    letter-spacing: 0.095em;
    margin-bottom: 0;
  }

  // Reset with no bg
  &--flush {
    min-height: auto;
  }
}

@keyframes glow {
  0%{
    opacity: 1;
    stroke: $color-warning;
  }
  5%{
    opacity: 0.2;
  }
  70%{
    opacity: 0.8;
    stroke: $color-warning;
  }
  74%{
    opacity: 0;
  }
  80%{
    opacity: 0.5;
    stroke: $color-warning;
  }
  84%{
    opacity: 0;
  }
  90%{
    opacity: 1;
    stroke: $color-warning;
  }
  100%{
    opacity: 0.3;
  }
}
