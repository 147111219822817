// Global variables
$font-family-base: -apple-system,BlinkMacSystemFont,"IBM Plex Mono","Segoe UI","Helvetica Neue",Arial,sans-serif;
$font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
$font-size-base: 16px;
$text-weight-base: 400;
$line-height-base: 1.5;
$body-color: #ffffff;
$background-color: #00000f;

$link-hover: #3989ef;
$z-index: 1000;

// Font sizes
$font-size-h1: 30px;
$font-size-h2: 24px;
$font-size-h3: 22px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 15px;
$font-size-md: 15px;
$font-size-sm: 13px;
$font-size-xsm: 12px;
$font-size-xxsm: 11px;

// Font weight
$text-weight-light: 300;
$text-weight-regular: 400;
$text-weight-md: 500;
$text-weight-semibold: 700;
$text-weight-bold: 900;

// Colors
$color-white: #fff;
$color-gray: #636569;
$color-light: #f8f9fa;
$color-light-gray: #d2ddeb;
$color-black: #00000f;
$color-primary: #8149bf;
$color-info: #3eccb0;
$color-secondary: #636569;
$color-success: #35ca7d;
$color-warning: #fde995;
$color-danger: #ff3358;
$color-orange: #ef655d;
$color-dark: #0f0e25;
$color-dark-blue: #00203e;
$color-light: #f8f9fa;
$color-marv: #8149bf;

$color-link: #fff;
$color-link-hover: $color-warning;

// Disabled
$input-disabled-color: $color-light-gray;

// Buttons
$btn-radius: 2rem;
$btn-bottom-color: rgb(129 73 191 / 18%);
// General input variables
$input-padding: 0.375rem 0.625rem;

// Screen sizes
$screen-md-min: 992px;
$screen-sm-max: 768px;
