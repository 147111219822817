.menu {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 0;
  margin: 0;
  list-style-type: none;
  z-index: 4;

  &-logo {
    display: flex;
    align-items: center;
    flex: 1;
    text-decoration: none;

    &__title {
      color: #fff;
      font-size: $font-size-h3;
      font-weight: $text-weight-bold;
      margin-left: 1rem;
      text-transform: uppercase;
    }
  }

  &-item {
    display: none;
    padding: .75rem;
    width: 100%;

    > a, .btn {
      display: block;
      text-transform: uppercase;
      padding: 1rem .5rem;
    }
  }

  &-toggle {
    position: absolute;
    top: .5rem;
    right: 0;
  }

  @media all and (max-width: $screen-md-min) {
    &--active {
      flex-direction: column;

      &:after {
        content: '';
        position: fixed;
        background: $background-color;
        width: 100%;
        height: 100%;
        inset: 0;
        z-index: -1;
      }

      .menu-item {
        display: block;

        > a, .btn {
          justify-content: flex-start;
          font-size: 2rem;
          font-weight: bold;
          text-align: left;
          border: none !important;
          padding: 1rem .5rem !important;
        }
      }
    }
  }
}

/* Desktop menu */
@media all and (min-width: $screen-md-min) {
  .menu {
    flex-wrap: nowrap;
    background: none;

    &-item {
      position: relative;
      display: block;
      width: auto;
    }
    &-toggle {
      display: none;
    }
  }
}

