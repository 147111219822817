$border-color: rgb(255 255 255 / 4%);

@media(min-width: $screen-md-min) {
  .col-md-border {
    &:not(:last-child) {
      border-right: 1px solid $border-color;
    }

    & + .col-md-border {
      border-left: 1px solid $border-color;
      margin-left: -1px;
    }
  }
}
