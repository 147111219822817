.staking {

  &-nft-wrapper {
    @include display-flex();
    flex-wrap: wrap;
    margin-left: -.125rem;
    margin-right: -.125rem;

    > img {
      width: 3.5rem;
      height: 3.5rem;
      border: .25rem inset transparent;
      margin: .125rem;
      cursor: pointer;
      @extend .transition-ease-primary;

      &:hover, &:active, &.nft-img--selected {
        border-color: $color-warning;
      }
    }
  }
}

.nft-img {
  &--disabled {
    pointer-events: none;
  }
}
