.btn {
  @include display-flex(center, center);
  position: relative;
  font-weight: 700;
  font-size: 1rem;
  color: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: .75rem 1.25rem;
  text-transform: uppercase;
  border: 2px solid transparent;
  outline: none !important;
  -webkit-border-radius: $btn-radius;
  -moz-border-radius: $btn-radius;
  border-radius: $btn-radius;

  &:hover, &:active, &:focus {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }

  &:disabled, .btn-disabled {
    opacity: 0.6;
    border-color: rgba(129, 73, 191, 0.18);
    background-color: transparent;
  }

  // Sizes
  &-xsm {
    padding: .188rem .75rem !important;
    font-size: $font-size-xsm;
  }

  &-sm {
    padding: .25rem .75rem;
    font-size: $font-size-sm;
  }

  &-md {
    padding: .75rem 1.25rem !important;
    font-size: $font-size-md !important;
  }

  &-lg {
    padding: .75rem 1.5rem !important;
  }

  // Colors
  &-default {
    background: linear-gradient($background-color, $background-color) padding-box,
    linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%) border-box;

    &:hover, &:active, &:focus {
      color: $color-light-gray !important;
      border-color: rgba(255, 255, 255, 0.25);
    }
    @media(max-width: $screen-sm-max) {
      border-color: rgba(255, 255, 255, 0.25);
    }
  }

  &-primary {
    background: linear-gradient($background-color, $background-color) padding-box,
    linear-gradient(180deg, rgb(239 101 93 / 75%) 0%, $btn-bottom-color 50%) border-box;

    &:hover, &:active, &:focus {
      color: #fff !important;
      border-color: rgb(239 101 93 / 75%);
      background-color: transparent;
    }
    @media(max-width: $screen-sm-max) {
      border-color: rgb(239 101 93 / 75%);
    }
  }

  &-secondary {
    background: linear-gradient($background-color, $background-color) padding-box,
    linear-gradient(180deg, $color-white 0%, $btn-bottom-color 100%) border-box;

    &:hover, &:active, &:focus {
      color: #fff !important;
      border-color: $color-white;
    }
    @media(max-width: $screen-sm-max) {
      border-color: $color-white;
    }
  }

  &-warning {
    color: $color-warning !important;
    background: linear-gradient($background-color, $background-color) padding-box,
    linear-gradient(180deg, $color-warning 0%, $btn-bottom-color 100%) border-box;

    &:hover, &:active, &:focus {
      color: $color-warning !important;
      border-color: $color-warning;
    }
    @media(max-width: $screen-sm-max) {
      border-color: $color-warning;
    }
  }

  &-success {
    background: linear-gradient($background-color, $background-color) padding-box,
    linear-gradient(180deg, $color-success 0%, $btn-bottom-color 100%) border-box;

    &:hover, &:active, &:focus {
      border-color: $color-success;
    }
    @media(max-width: $screen-sm-max) {
      border-color: $color-success;
    }
  }

  // Button spinner
  &--is-loading {
    padding-left: 1.75rem !important;

    &:before {
      content: '';
      position: absolute;
      inset: -1px;
      opacity: 0;
      background: #fff;
    }

    > .loader-spinner {
      position: absolute;
      left: 0;
      opacity: 1;
      margin-left: .25rem;
      margin-top: -.125rem;

      svg circle {
        stroke: #101573 !important;
        stroke-width: 4;
      }
    }
  }

  &:disabled {
    opacity: 0.4;
  }

  // Icon
  & > svg {
    margin-right: .5rem;
  }

  // Group
  &-group {
    &--separate {

      > .btn {
        -webkit-border-radius: $btn-radius !important;
        -moz-border-radius: $btn-radius !important;
        border-radius: $btn-radius !important;

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }

    @media(max-width: $screen-sm-max) {
      flex-direction: column;
      width: 100%;

      > .btn {
        margin-right: 0 !important;

        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
