.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background:
    linear-gradient($background-color, $background-color) padding-box,
    linear-gradient(180deg, rgba(129, 132, 255, 0.2) 0%, rgba(129, 132, 255, 0) 100%) border-box;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(129, 132, 255, 0.2) 0%, rgba(129, 132, 255, 0) 100%);
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  border-radius: 1.5rem;
  overflow: hidden;

  // Color design on top of the card
  &--cover {
    &:after {
      content: '';
      background: linear-gradient(90deg, $color-primary 1.74%, $color-orange 100%);
      height: 2px;
      width: 5rem;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--left {
      &:after {
        width: 3.375rem;
        position: absolute;
        top: 4.625rem;
        left: 0;
        transform: translate(-50%, -50%) rotate(270deg);
      }
    }
  }

  &-header {
    position: relative;
    padding: 1rem;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #1a1a1a;
    }
  }

  &-body {
    padding: 2rem;
    background: linear-gradient(180deg, rgba(129, 132, 255, 0.03) 0%, rgba(129, 132, 255, 0.01) 100%);
    overflow: hidden;

    .card > .card-body {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.01) 100%);
      -webkit-border-radius: 1rem;
      -moz-border-radius: 1rem;
      border-radius: 1rem;
    }
  }

  @media(max-width: $screen-sm-max) {
    background:
      linear-gradient($background-color, $background-color) padding-box,
      linear-gradient(rgba(129, 132, 255, 0.2), rgba(129, 132, 255, 0.2)) border-box;

    &-body {
      background: rgba(129, 132, 255, 0.03);
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}
