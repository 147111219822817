@function if-important($important){
  @return #{if($important, '!important', '')};
}

// clearfix
@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

// Box sizing
@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
  -moz-box-sizing: $box-model; // Firefox <= 19
  box-sizing: $box-model;
}

// Box shadow
@mixin box-shadow($top:null, $left:null, $blur:null, $color:null, $inset: false) {
  @if $top == null {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  } @else {
    @if $inset {
      -webkit-box-shadow: inset $top $left $blur $color;
      -moz-box-shadow: inset $top $left $blur $color;
      box-shadow: inset $top $left $blur $color;
    } @else {
      -webkit-box-shadow: $top $left $blur $color;
      -moz-box-shadow: $top $left $blur $color;
      box-shadow: $top $left $blur $color;
    }
  }
}

// User select
@mixin user-select($type:none) {
  -webkit-user-select: $type;
  -moz-user-select: $type;
  -ms-user-select: $type;
  user-select: $type;
}

// Text Shadow
@mixin text-shadow($top:null, $left:null, $blur:null, $color:null) {
  @if $top == null {
    text-shadow: none;
  } @else {
    text-shadow: $top $left $blur $color;
  }
}

// Font size
@mixin font-size($sizeValue: 13 ){
  font-size: (0.125 * $sizeValue) + rem;
}

// Line height
@mixin line-height($heightValue: 12 ){
  line-height: (0.125 * $heightValue) + rem;
}

// Display Flex
@mixin display-flex($position:null, $justify:null, $nowrap:null, $noshrink:null, $nogrow:null, $flow:null) {
  display: flex;
  @if $flow {
    flex-flow: $flow;
  }
  @if $position {
    align-items: $position;
  }
  @if $justify {
    justify-content: $justify;
  }
  @if $nowrap {
    flex-wrap: nowrap;
  }
  @if $noshrink {
    flex-shrink: 0;
  }
  @if $nogrow {
    flex-grow: 0;
  }
}

// Truncate text with ...
@mixin truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// Center block
@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Border radius
@mixin border-radius($top-left:0, $top-right:null, $bottom-right:null, $bottom-left:null, $important: false) {
  border-radius: $top-left $top-right $bottom-right $bottom-left if-important($important);
  -moz-border-radius: $top-left $top-right $bottom-right $bottom-left if-important($important);
  -webkit-border-radius: $top-left $top-right $bottom-right $bottom-left if-important($important);
  //background-clip: padding-box;  // stops bg color from leaking outside the border:
  background-clip: initial;
}

// Transitions

@mixin transition($transition...) {
  -webkit-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}

@mixin transition-ease($object:all, $sec:0.2s, $animate:ease-in-out) {
  -webkit-transition: $object $sec $animate;
  -o-transition: $object $sec $animate;
  transition: $object $sec $animate;
}

// Transform - translateX
@mixin transform-translateX($percent:0%, $scale:1) {
  -webkit-transform: translateX($percent) scale($scale);
  -ms-transform: translateX($percent) scale($scale);
  transform: translateX($percent) scale($scale);
}
// Transform - translateY
@mixin transform-translateY($percent:0%, $scale:1) {
  -webkit-transform: translateY($percent) scale($scale);
  -ms-transform: translateY($percent) scale($scale);
  transform: translateY($percent) scale($scale);
}
// Transform - rotate
@mixin transform-rotate($deg:0deg, $scale:1) {
  -webkit-transform: rotate($deg) scale($scale);
  -ms-transform: rotate($deg) scale($scale);
  transform: rotate($deg) scale($scale);
}

@mixin transform-translate3d($x:0, $y:0, $z:0) {
  -webkit-transform: translate3d($x, $y, $z);
  -moz-transform: translate3d($x, $y, $z);
  -o-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}

// Hover
@mixin hover {
  &:hover { @content; }
}

// Focus
@mixin focus {
  &:focus { @content; }
}

// Hover and focus
@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

// Empty
// Empty content, collapse automatically
@mixin empty {
  &:empty {
    display: none;
  }
}

// Badge
@mixin badge($bg) {
  color: color-yiq($bg);
  background-color: $bg;

  @at-root a#{&} {
    @include hover-focus {
      background-color: darken($bg, 10%);
    }

    &:focus,
    &.focus {
      outline: 0;
      box-shadow: 0 0 0 2px rgba($bg, .5);
    }
  }
}
