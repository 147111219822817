.testim {
  min-height: 30rem;
}

.testim .wrap {
  position: relative;
  width: 100%;
  max-width: 1020px;
  margin: auto;
}

.testim .arrow {
  @include display-flex(center, center);
  position: absolute;
  background-color: rgb(255 255 255 / 10%);
  width: 56px;
  height: 56px;
  top: 50%;
  padding: 5px;
  cursor: pointer;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  border-radius: 0.5rem;
  z-index: 2;

  @media(max-width: $screen-sm-max) {
    display: none;
  }
}

.testim .arrow:before {
  cursor: pointer;
}

.testim .arrow.left {
  left: 0;
}

.testim .arrow.right {
  right: 0;
}

.testim .dots {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: -3rem;
  left: 0;
  z-index: 3;
}

.testim .dots .dot {
  list-style-type: none;
  display: inline-block;
  width: 3rem;
  height: .25rem;
  background: rgba(15, 14, 37, 1);
  margin: 0 .25rem;
  cursor: pointer;
  position: relative;
  @extend .transition-ease-primary;
}

.testim .dots .dot.active,
.testim .dots .dot:hover {
  background: linear-gradient(90deg, $color-marv 1.74%, $color-orange 100%);
}

.testim .dots .dot.active {
  @extend .transition-ease-primary;
}

.testim .cont {
  @include display-flex();
  justify-content: center;
  position: relative;
  overflow: hidden;

  > .card {
    position: relative;
    max-width: 48rem;

    .card-body {
      padding: 2rem;
    }

    &:before {
      content: '';
      position: absolute;
      top: 1rem;
      left: 3rem;
      width: 5.375rem;
      height: 4.75rem;
      background-image: url("data:image/svg+xml,%3Csvg width='88' height='77' viewBox='0 0 88 77' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M87.7 52.7c-.5-4.6-2.4-8.7-5.7-12a20 20 0 0 0-15.7-6.5c-2 .1-4 .6-6 .9l.4-2.3a84.3 84.3 0 0 1 8-21.4L74 1.8c.3-.5.4-1 .6-1.6l-.2-.2c-.4.2-.9.3-1.2.6C70.5 2.4 67.8 4 65.4 6a52 52 0 0 0-16.2 26A79 79 0 0 0 46 52.8c0 2.1 0 4.2.4 6.3 1.7 8.6 8.4 15 17.2 16.6 9.6 1.7 19.5-4 22.8-13.2.7-1.9 1-3.8 1.4-5.8v-3.4l-.1-.6ZM18.2 34.4l-4 .7V35l.1-.5A81.5 81.5 0 0 1 22 12.7l6-11 .6-1.5-.3-.2-1.2.6c-3 2.1-6.1 4-9 6.5-6.7 5.6-11 13-13.8 21.2C1.7 35.8.3 43.7 0 51.7a26 26 0 0 0 1 9.8 20.4 20.4 0 0 0 16.6 14.2c6.4 1 12.1-.6 17-4.8a20.9 20.9 0 0 0-16.4-36.5Z' fill='%2313142F'/%3E%3C/svg%3E");
    }
  }
}

.testim .testim-content-item {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.testim .testim-content-item.inactive {
  opacity: 0;
}

.testim .testim-content-item.active {
  position: relative;
  opacity: 1;
  -webkit-animation: testim-content-in .5s ease-in-out;
  -moz-animation: testim-content-in .5s ease-in-out;
  -ms-animation: testim-content-in .5s ease-in-out;
  -o-animation: testim-content-in .5s ease-in-out;
  animation: testim-content-in .5s ease-in-out;
}

.testim-content-item img {
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  margin: auto;
  border-radius: 50%;
}

.testim-content-item p {
  font-size: 1rem;
  line-height: 1.7;
}

@-webkit-keyframes testim-content-in {
  from {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-moz-keyframes testim-content-in {
  from {
    opacity: 0;
    -moz-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
}

@-ms-keyframes testim-content-in {
  from {
    opacity: 0;
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@-o-keyframes testim-content-in {
  from {
    opacity: 0;
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes testim-content-in {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
