.footer {
  background-color: $color-dark;
  padding-top: 2rem;
  padding-bottom: 1rem;

  > .container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid $color-dark-blue;
  }

  &-sub {
    &-wrapper {
      @include display-flex(center, space-between);
    }

    &-content {
      width: 45%;
      margin-left: 7.5rem;
    }

    &-social {
      @include display-flex(center);

      > a {
        padding: 0 .75rem;
      }
    }
  }

  @media(max-width: $screen-md-min) {
    &-sub-wrapper {
      flex-direction: column;
    }
    .menu-logo__title {
      display: none;
    }
    &-sub-content {
      width: 100%;
      margin-left: 0;
    }
  }

  &-links {
    list-style-type: none;
    padding-left: 0;

    &-item {
      @include display-flex(center);
      font-size: 1rem;
      margin: 1rem 0;

      > svg {
        margin-right: .75rem;
      }
    }
  }
}
