.label {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;

  // Colors
  &-primary {
    color: $color-primary;
    background-color: darken($color-primary, 38);
  }
  &-success {
    color: $color-success;
    background-color: darken($color-success, 40);
  }
  &-warning {
    color: $color-warning;
    background-color: darken($color-warning, 40);
  }
  &-danger {
    color: $color-danger;
    background-color: darken($color-danger, 52);
  }

  // Types
  &-pill {
    padding: 0.25rem 0.5rem;
    border-radius: 10rem;
  }
}
