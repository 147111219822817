// Text sizes
.text-xxsm {
  font-size: $font-size-xsm !important;
}
.text-xsm {
  font-size: $font-size-sm !important;
}
.text-sm {
  font-size: $font-size-md !important;
}
.text-md {
  font-size: $font-size-md !important;
}
.text-lg {
  font-size: $font-size-h5 !important;
}
.text-xlg {
  font-size: $font-size-h4 !important;
}
.text-2xlg {
  font-size: $font-size-h3 !important;
}

// Colors
.text-primary {
  color: $color-primary !important;
}
.text-secondary {
  color: $color-secondary !important;
}
.text-success {
  color: $color-success !important;
}
.text-danger {
  color: $color-danger !important;
}
.text-warning {
  color: $color-warning !important;
}
.text-light {
  color: #8e95a3 !important;
}
.text-info {
  color: $color-info !important;
}
.text-dark {
  color: $color-dark !important;
}
.text-muted {
  color: $color-gray !important;
}
.text-highlight {
  letter-spacing: 1px;
  background: linear-gradient(90deg, $color-marv 1.74%, $color-orange 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 0px 20px rgba(225, 97, 104, 0.25);
}

// Weight
.text-weight-lighter {
  font-weight: lighter;

  > strong {
    font-weight: normal;
  }
}

// Text spacing
.text-spacing-0 {
  letter-spacing: normal;
}
