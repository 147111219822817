.headline-wrapper {
  @include display-flex(center, center);
  flex-direction: column;
  text-align: center;
  margin-bottom: 7rem;

  @media screen and (max-width: $screen-md-min) {
    margin-bottom: 4rem;
  }
}

.headline {
  > h1 {
    position: relative;
    color: $color-white;
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.25rem;

    &:before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m9.6 4.4-1-4c-.1-.6-1-.6-1.2 0l-1 4c-.2 1-1 1.8-2 2l-4 1c-.6.1-.6 1 0 1.2l4 1c1 .2 1.8 1 2 2l1 4c.1.6 1 .6 1.2 0l1-4c.2-1 1-1.8 2-2l4-1c.6-.1.6-1 0-1.2l-4-1c-1-.2-1.8-1-2-2Z' fill='%238184FF'/%3E%3C/svg%3E");
      width: 1rem;
      height: 1rem;
      position: absolute;
      left: -1.5rem;
      bottom: -0.5rem;
    }

    &:after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5.5 10.7.6c-.2-.8-1.4-.8-1.6 0L8.1 5.5A3.5 3.5 0 0 1 5.5 8L.6 9.2c-.8.2-.8 1.4 0 1.6l4.9 1.1c1.2.4 2.2 1.4 2.6 2.6l1.1 4.9c.2.8 1.4.8 1.6 0l1.1-4.9a3.5 3.5 0 0 1 2.6-2.6l4.9-1.1c.8-.2.8-1.4 0-1.6l-4.9-1.1A3.5 3.5 0 0 1 12 5.5Z' fill='%23FDE995'/%3E%3C/svg%3E");
      width: 1.25rem;
      height: 1.25rem;
      position: absolute;
      top: -0.5rem;
      right: -1.5rem;
    }
  }

  > img {
    width: 100%;
    height: 1.25rem;
  }
}
